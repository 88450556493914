import React, { useState, useEffect } from "react";
import { motion, useAnimationControls } from "framer-motion";
import Countbg from "../../assets/images/Countbg.svg";

const Intro = ({ countFinishHandler, drill }) => {
  const counterStart = 3;
  const [count, setCount] = useState(counterStart);
  const controls = useAnimationControls()

  useEffect(() => {

    if (count > 0) {
      if (count == counterStart) {
        controls.set({opacity: 0, scale: 1.5})
        controls.start(i => ({
          opacity: 1,
          scale: 0,
          transition: { duration: 1 },
        }))
      }

      const timer = setTimeout(() => {
        setCount((prevState) => prevState - 1);

        controls.set({opacity: 0, scale: 1.5})
        controls.start(i => ({
          opacity: 1,
          scale: 0,
          transition: { duration: 1 },
        }))
            
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
      
    } else if (count === 0) {
      const timer = setTimeout(() => {
        countFinishHandler();
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [count]);

  return (
    <div>
      <p className="math-intro-title math-intro-title__center">
        {drill.title}
      </p>
      <div className="count">
        <div
          className="count-bg"
          style={{ backgroundImage: `url(${Countbg})` }}
        >

          <motion.div animate={controls} >
            <p className="count-start">{count > 0 ? count : "Go!"}</p>
          </motion.div>
          
        </div>
      </div>
    </div>
  );
};

export default Intro;
