import React, { useEffect, PureComponent } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Label } from 'recharts';


const PreviousTimes = ({ previousTimes }) => {

    useEffect(() => {

       console.log(previousTimes)
      }, [previousTimes]);    

    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            let d = previousTimes[label];

            return (
            <div className="previoustimes-tooltip">
                <p className="">Date: {` ${new Date(d.createdAt).toString().split(' GMT')[0] } `}</p>
                <p className="">Time Spent: {` ${payload[0].value} seconds `}</p>
                <p className="">Correct: {` ${d.summary.percentScore}% `}</p>
            </div>
            );
        }
        return null;
    };

  return (

    <div className="previousTimes siteDefaultFont">

        { previousTimes === undefined || previousTimes.length === 0 ? null : 

            <ResponsiveContainer width="100%" height={300} className="previousTimesResponsive">

                <BarChart
                    // width={700}
                    // height={300}
                    data={previousTimes}
                    margin={{
                        top: 50,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis label="" tick={false}>
                        <Label value='Previous sessions' position='insideBottom' style={{textAnchor: 'left'}} />
                    </XAxis>

                    <YAxis label="" tickCount="10" angle={0} >
                        <Label angle={-90} value='Time spent (in seconds)' position='insideBottomLeft' style={{textAnchor: 'left'}} />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />}/>
                    {/* <Legend /> */}
                    <Bar dataKey="summary.timeSpent" fill="#3629B7">
                        <LabelList dataKey="summary.timeSpent" position="top" />
                        {previousTimes.map((entry, index) => (
                            <Cell key={`cell-${index}`} 
                                strokeWidth={index === previousTimes.length - 1 ? 1 : 0}
                                stroke="black"
                                radius={[10, 10, 0, 0]}
                                fill={entry.summary?.percentScore === 100 ? '#55A55E': '#F75842' }/>
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        }
    </div>
  );
};

export default PreviousTimes;
