import React, { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import ScoreImg from "../../assets/images/Score.svg";
import RightImg from "../../assets/images/Right.svg";
import WrongImg from "../../assets/images/Wrong.svg";
import PreviousTimes from "./PreviousTimes"
import AnswersChart from "./AnswersChart"
import { Context } from "../../app/Context";
import Axios from "../../utils/Axios";
import Confetti from "../confetti/Confetti";


const Summary = ({ doitagainHandler, answers, summary }) => {
  const context = useContext(Context);
  const [keyPress, setKeyPress] = useState(0);
  const [previousTimes, setPreviousTimes] = useState([]);
  const [previousBestTime, setPreviousBestTime] = useState();


  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Enter") {
        setKeyPress((prev) => prev + 1);
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);


  useEffect(() => {
    if (summary.isPushedToDB) {
      // Retrieve the previous records
      const params = {
        params: {
          sessionID: context.sessionID,
          drillID: context.modal.drill._id,
          summary: true,
          userID: context.userInfo.userID
        }
      };

      Axios.get(`/api/exams`, params)
      .then((response) => {
        let bestTime = null;
        let sortedItems = response.data.response.Items.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
        
        sortedItems.forEach((it, index) => {
          if (index < response.data.response.Items.length - 1 
              && it.summary.percentScore === 100
              // && context.configurationFile.defaultParams.totalQuestions === it.summary.correctAnswer // Seems unecessary since we already look at the percentage
              && (bestTime == null || bestTime > it.summary.timeSpent))
            bestTime = it.summary.timeSpent
        })
        setPreviousBestTime(bestTime)
        setPreviousTimes(sortedItems);
      })
      .catch((e) => {
        console.log("Something went wrong while retrieving the previous records");
        console.log(e);
      });
    }
  }, [summary, context]);

  if (keyPress > 1) {
    return doitagainHandler();
  }


  return (
    <div>

      <Confetti answers={answers} drillStatus={context.drillStatus} summary={summary} previousBestTime={previousBestTime} />

      <div className="overview">
        <div
          className="score-score"
          style={{ backgroundImage: `url(${ScoreImg})` }}
        >
          <p className="score-score-number">{summary.timeSpent}</p>
          <p className="score-score-text">Seconds</p>
        </div>
        <div className="score-score-msg">
          <p className="score-score-gb">Great Job!</p>
          <p className="score-score-des">
            Now, celebrate your victories, and identify what you could do better
            next time.
          </p>
        </div>
        <div className="overview-score">
          <div className="overview-card">
            <p className="score-completion-complete">Correct</p>
            <p className="score-completion-number">
              {summary.percentScore}%
            </p>
          </div>
          {/* <div className="overview-card">
            <div className="score-completion-number score__total-number">
              <p>{mathLength}</p>
            </div>
            <p className="score-completion-complete">Total Questions</p>
          </div> */}
          <div className="overview-card">
          <p className="score-completion-complete">Time Spent</p>
            <div className="score-completion-number score__total-number">
              <p>{summary.timeSpent}</p>
            </div>
          </div>
          <div className="overview-card">
          <p className="score-completion-complete">Previous Record</p>
            <div className="score-completion-number score__total-correct">
              <p className={` ${ summary.wrongAnswer === 0 && summary.timeSpent <= previousBestTime  ? "blink_me" : "" } `}>{previousBestTime}</p>
            </div>
          </div>
          <div className="overview-card">
          <p className="score-completion-complete">Wrong answers</p>
            <div className="score-completion-number score__total-wrong">
              <p>{summary.wrongAnswer}</p>
            </div>
          </div>
        </div>

        <div className="summary-section">
          <p className="summary-section-title">Previous performances</p>
          <PreviousTimes previousTimes={previousTimes}/>
        </div>

        <div className="summary-answers">

          <div className="summary-section summary-answers-chart">
            {console.log(answers)}
            <p className="summary-section-title">Time spent per question (in seconds)</p>
            <AnswersChart answers={answers}/>
          </div>


          <div className="summary-section summary-answers-list">
            <p className="summary-section-title">Answers and corrections</p>

            {/* Questions Loop */}
            {answers.map((answer) => (
              <div className="short-overview-items" key={answer._id}>

                <div className="short-overview-items-check">
                  <div>
                    <img
                      src={answer.isCorrect ? RightImg : WrongImg}
                      alt=""
                      className="short-overview-items-icon"
                    />
                  </div>

                  <div>
                    <p>{`${answer.firstNumber} ${answer.operationDisplayed} ${answer.secondNumber} = ${answer.resultNumber}`}</p>
                    
                    {/* Display textual prompt when available */}
                    {answer.prompt? (<div><p className='short-overview-items-prompt'>{answer.prompt}</p></div>) : null}

                    {/* Display what the stutent answered when incorrect */}
                    {answer.isCorrect ? null : context.modal.drill.ranges?.total?.toGuess !== undefined && context.modal.drill.ranges?.total?.toGuess === false ? (
                      <p className="short-overview-items-check-right-ans">
                        You answered: {`${context.modal.drill.ranges?.first?.toGuess ? answer.userAnswer : answer.firstNumber} ${answer.operationDisplayed} ${context.modal.drill.ranges?.second?.toGuess ? answer.userAnswer : answer.secondNumber } = ${answer.resultNumber}`}
                      </p>
                    ) : (
                      <p className="short-overview-items-check-right-ans">
                        You answered: {answer.userAnswer}
                      </p>
                    )
                  }
                  </div>
                </div>

                {/* Time spent for each question */}
                <p className="short-overview-sec-dec">
                  Solved in{" "}
                  <span className="short-overview-sec-dec__number">
                    {answer.second}
                  </span>{" "}
                  Sec
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <motion.div
        className="score-doit-btn"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={doitagainHandler}
      >
        Do it again
      </motion.div>
    </div>
  );
};

export default Summary;
