import React from "react";
import { motion } from "framer-motion";

const ProgressBarCompo = ({ runningDrill, answers }) => {
  const percent = (100 / runningDrill.questions.length) * answers.length;
  return (
    <div className="progress-bar">
      <p className="progress-bar-percent">{`${answers.length} of ${runningDrill.questions.length}`}</p>
      <motion.div
        animate={{
          width: `${percent}%`,
        }}
        transition={{ duration: 2 }}
        className="progress-bar-percent-bg"
      ></motion.div>
    </div>
  );
};

export default ProgressBarCompo;
