import React, { useState, useContext, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Context } from "../../app/Context";
import Axios from "../../utils/Axios";
import { detect } from "detect-browser";
import ReactGA from "react-ga4";

const Header = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayNav, setDisplayNav] = useState(false);
  const [navShow, setNavShow] = useState(false);

  const context = useContext(Context);
  const header = context.configurationFile.header;
  const params = window.location.pathname;

  useEffect(() => {
    ReactGA.initialize("G-B3H7FN1E9M");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const createSessionFromAPI = (payload) => {
    Axios.post("/api/sessions", payload)
      .then((response) => {
        console.log(
          `Received new session ID from server ${response.data.sessionID}`
        );
        localStorage.setItem("sessionID", response.data.sessionID);
        context.setSessionID(response.data.sessionID);
        context.googleAnalyticsEvent("create_session", response.data.sessionID);
      })
      .catch(() => {
        console.log("Something went wrong!");
      });
  };

  const createSessionID = (userID) => {
    const browser = detect();
    const url = (({
      host,
      hostname,
      href,
      origin,
      pathname,
      port,
      protocol,
    }) => ({ host, hostname, href, origin, pathname, port, protocol }))(
      window.location
    );

    const sessionObj = {
      browserName: browser.name,
      browserType: browser.type,
      browserOS: browser.os,
      browserVersion: browser.version,
      browserLocales: navigator.languages,
      geoInfo: null,
      node_env: process.env.NODE_ENV,
      url,
      userID,
    };
    console.log(sessionObj);

    Axios.get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        const {
          country_name,
          country_code,
          city,
          latitude,
          longitude,
          region,
          region_code,
          timezone,
        } = data;

        const info = {
          country_name,
          country_code,
          city,
          latitude,
          longitude,
          region,
          region_code,
          timezone,
        };

        sessionObj.geoInfo = info;
        createSessionFromAPI(sessionObj);
      })
      .catch((error) => {
        console.log("Couldn't retrieve user geolocation information");
        console.log(error);

        createSessionFromAPI(sessionObj);
      });
  };

  const testSessionAndCreateNewIfInvalid = (sessionID, userID) => {
    console.log(
      `testSessionAndCreateNewIfInvalid with ${sessionID} and ${userID}`
    );

    // If no session ID exists, then we create one
    if (sessionID == null) createSessionID(userID);
    // Otherwise we test if it's a valid session and create one if needed
    else {
      Axios.get(`/api/sessions/${context.sessionID}`)
        .then((response) => {
          console.log(
            `Local Session ID Found ${context.sessionID} for userID ${response.data.userID} vs ${userID}`
          );

          // Check that the user ID corresponds, if no create a new session
          if (response.data.userID !== userID) {
            console.log(`Users don't match we need a new session ID`);
            createSessionID(userID);
          }
        })
        .catch((e) => {
          if (e.response.status === 404) createSessionID(userID);
          else console.log("Something went wrong!");
        });
    }
  };

  useEffect(() => {
    let userID = searchParams.get("user");

    // We try to use a specific user
    if (userID !== undefined && userID !== null) {
      Axios.get(`/api/users/${userID}`)
        .then((response) => {
          // We recognized the user
          context.setUserInfo(response.data);
          console.log(`We recognized user ${userID}`);
          console.log(response.data);
          console.log(context.userInfo);
        })
        .catch((e) => {
          if (e.response.status === 404) console.log("User not found");
          else
            console.log("Something went worong while validating the user ID");

          // Invalid user?
          context.setUserInfo({});
          userID = null;
        })
        .finally(() => {
          testSessionAndCreateNewIfInvalid(context.sessionID, userID);
        });
    } else {
      // No user specific, just test current session ID and create new one if needed
      console.log(`No user specified`);
      testSessionAndCreateNewIfInvalid(context.sessionID, userID);
    }
  }, [searchParams]);

  return (
    <div className="header">
      <div className="container">
        <div className="header-content">
          <div className="header-logo">
            {navShow ? (
              <i
                className="fa-solid fa-xmark header-bar-icon"
                onClick={() => {
                  setNavShow(false);
                  setTimeout(() => {
                    setDisplayNav(false);
                  }, 1000);
                }}
              ></i>
            ) : (
              <i
                className="fa-solid fa-bars header-bar-icon "
                onClick={() => {
                  setDisplayNav(true);
                  setNavShow(true);
                }}
              ></i>
            )}
            <Link to={`${header.logo.url}?${searchParams.toString()}`}>
              <img src={header.logo.image} alt="Logo" />
            </Link>
          </div>

          {context.userInfo === undefined ||
          context.userInfo === null ||
          !context.userInfo.userID ? (
            ""
          ) : (
            <div className="nav-desktop header-welcome">
              Hi {context.userInfo.firstName}!
            </div>
          )}

          <nav className="nav-desktop">
            {header.nav.map((el) => 
              (
                
                <Link
                  to={`${el.url}?${searchParams.toString()}`}
                  className={` ${
                    params === el.url
                      ? "nav-desktop-item-active"
                      : "nav-desktop-item"
                    }
                    ${el.display ? '' : ' hidden'}
                  `}
                  key={el._id}
                >
                  {el.name}
                </Link>
                
            ))}
          </nav>
        </div>
      </div>
      {displayNav && (
        <nav className="nav-mobile">
          {header.nav.map((el) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: navShow ? 1 : 0,
                height: navShow ? "50px" : "0px",
              }}
            >
              <Link
                to={`${el.url}?${searchParams.toString()}`}
                className={` ${
                  params === el.url
                    ? "nav-desktop-item-active nav-mobile-item"
                    : "nav-desktop-item nav-mobile-item"
                }
                ${el.display ? '' : ' hidden'}
                `}
                key={el._id}
              >
                {el.name}
              </Link>
            </motion.div>
          ))}
        </nav>
      )}
    </div>
  );
};

export default Header;
