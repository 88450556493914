import React from "react";
import Header from "../components/headerFooter/Header";
import Footer from "../components/headerFooter/Footer";
import Filter from "../components/home/Filter";
import Drills from "../components/home/Drills";
import ExamModal from "../components/modal/ExamModal";

const Home = () => {
  return (
    <div>
      <div className="wrapper">
        <Header />
        <Filter />
        <Drills />
        <ExamModal />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
