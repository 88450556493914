import React, { useEffect, PureComponent, useState } from "react";
import Axios from "../../utils/Axios";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Label } from 'recharts';
import { HeatMapGrid } from 'react-grid-heatmap'



const StatsDrill = ({ drillID }) => {
    const [drillData, setDrillData] = useState();
    const [chartableData, setChartableData] = useState();
    const [heatmapData, setHeatmapData] = useState({x: [], y: [], data: [], max: 0});

    useEffect(() => {

        console.log(drillID);

        if (drillID === undefined)
            return;
        console.log(`Fetching questions for ${drillID}`);

        Axios.get("/api/questions", {
            params: {
                drillID
            }
        })
        .then((response) => {
            console.log(response.data.response)
            // setDrillData(JSON.stringify(response.data.response));
            setDrillData(response.data.response);
         })
        .catch(() => {
            console.log("Something went wrong!");
        });

    }, [drillID]);  


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            let d = chartableData[label];

            return (
            <div className="previoustimes-tooltip">
                <p className="">Equation: {` ${d.equation} `}</p>
                <p className="">Time Spent: {` ${d.avgTime} seconds `}</p>
                <p className="">Number of correct answers: {` ${d.n} `}</p>
            </div>
            );
        }
        return null;
    };    

    useEffect(() => {
        console.log(drillData);
        if (drillData === undefined)
            return;

        let d = []

        Object.keys(drillData.index).map((op) => (
            Object.keys(drillData.index[op]).map((firstNumber) => (
                Object.keys(drillData.index[op][firstNumber]).map((secondNumber) => {
                    d.push({
                        equation: `${firstNumber} ${op} ${secondNumber}`,
                        avgTime: drillData.index[op][firstNumber][secondNumber].stats.avgTime,
                        n: drillData.index[op][firstNumber][secondNumber].stats.numberCorrectAnswers,
                        firstNumber: parseInt(firstNumber),
                        secondNumber: parseInt(secondNumber)
                    })
                }) 
            ))           
        ))
        d = d.sort((a, b) => Math.min(a.firstNumber, a.secondNumber) == Math.min(b.firstNumber, b.secondNumber) ? a.firstNumber - b.firstNumber :  Math.min(a.firstNumber, a.secondNumber) - Math.min(b.firstNumber, b.secondNumber));
        setChartableData(d)
        // console.log(d);

        
        let d2 = {x: [], y: [], data: [], max: 0, min: 0}
        Object.keys(drillData.index).map((op) => {
            Object.keys(drillData.index[op]).map((firstNumber) => {
                if (parseInt(firstNumber) > d2.max)
                  d2.max = parseInt(firstNumber)

                 if (parseInt(firstNumber) < d2.min)
                  d2.min = parseInt(firstNumber)

                Object.keys(drillData.index[op][firstNumber]).map((secondNumber) => {
                    if (parseInt(secondNumber) > d2.max)
                      d2.max = parseInt(secondNumber)

                    if (parseInt(secondNumber) < d2.min)
                      d2.min = parseInt(secondNumber)
                          
                })           
            })

            for (let i = d2.min; i <= d2.max; i++) {
                d2.x.push(i)
                d2.y.push(i)
                let row = []
                for (let j = d2.min; j <= d2.max; j++) {
                    if (i.toString() in drillData.index[op] && j in drillData.index[op][i.toString()] && drillData.index[op][i.toString()][j.toString()].questions.length > 2)
                      row.push(drillData.index[op][i.toString()][j.toString()].stats.avgTime)
                    else
                      row.push(null)
                }
    
                d2.data.push(row)
            }
    
        })
  
    //    console.log(d2)
       setHeatmapData(d2)

    }, [drillData]);     

  return (


    <div className="">
       {/* {JSON.stringify(drillData.index, null, 2)} */}
       {/* {JSON.stringify(chartableData, null, 2)} */}
       {/* {JSON.stringify(heatmapData, null, 2)} */}

        { drillData && 
            <div>
                <br></br>
                <p>Statistics about drill {drillID}</p>
                <br></br>
                <p>Number of questions answered by students: {drillData.number}</p>
                <p>Average time to answer a question: {drillData.avgTimeCorrect} seconds</p>
                <p>Percentage of correct answers: {drillData.perctCorrect}</p>
                <br></br>
            </div>
        }


        <div>
            <HeatMapGrid width="100%" height={300}
                data={heatmapData.data}
                xLabels={heatmapData['x']}
                yLabels={heatmapData['y']}
                cellRender={(x, y, value) => (
                    <div title={`${x} x ${y}`}>{value}</div>
                  )}                
                cellHeight={"2rem"}
                xLabelsPos={"bottom"}
            />
        </div>
{/* 
        <div>
            <ResponsiveContainer width="100%" height={300} className="previousTimesResponsive">
                <BarChart
                    data={chartableData}
                    margin={{
                        top: 50,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <XAxis label="" tick={false}>
                        <Label value='Questions' position='insideBottom' style={{textAnchor: 'left'}} />
                    </XAxis>

                    <YAxis label="" tickCount="10" angle={0} >
                        <Label angle={-90} value='Time spent (in seconds)' position='insideBottomLeft' style={{textAnchor: 'left'}} />
                    </YAxis>
                    <Tooltip content={<CustomTooltip />}/>
                    <Bar dataKey="avgTime" fill="#3629B7">
                        <LabelList dataKey="equation" position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div> */}
        
    </div>
  );
};

export default StatsDrill;
