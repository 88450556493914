import React from "react";
import Header from "../components/headerFooter/Header";
import ContactUs from "../components/pages/ContactUs";
import Footer from "../components/headerFooter/Footer";

const ContactUsPage = () => {
  return (
    <div>
      <div className="wrapper">
        <Header />
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};

export default ContactUsPage;
