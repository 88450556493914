import React, { useContext, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Context } from "../../app/Context";
import ReactGA from "react-ga4";

const Footer = () => {
  const context = useContext(Context);
  const footer = context.configurationFile.footer;
  const params = window.location.pathname;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    ReactGA.initialize("G-B3H7FN1E9M");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <footer>
      <div className="container">
        <div className="footer-flex">
          <div>
            <Link to={`${footer.logo.url}?${searchParams.toString()}`}>
              <img src={footer.logo.image} alt="Logo" className="footer-logo" />
            </Link>
            <p className="footer-year">{footer.logo.year}</p>
          </div>
          <div className="footer-menu">
            {footer.nav.map((el) => (
              <Link
                to={`${el.url}?${searchParams.toString()}`}
                className={`
                ${
                  params === el.url
                    ? "nav-desktop-item-active"
                    : "nav-desktop-item"
                }
                    "footer-menu-item"`}
                key={el._id}
              >
                {el.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
