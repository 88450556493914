import React from "react";
import Header from "../components/headerFooter/Header";
import Stats from "../components/pages/Stats";
import Footer from "../components/headerFooter/Footer";

const StatsPage = () => {
  return (
    <div>
      <div className="wrapper">
        <Header />
        <Stats />
      </div>
      <Footer />
    </div>
  );
};

export default StatsPage;
