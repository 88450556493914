import React, { useEffect, PureComponent } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Label } from 'recharts';


const AnswersChart = ({ answers }) => {

    useEffect(() => {
       console.log(answers)
      }, [answers]);    

    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            let d = answers[label];

            return (
            <div className="previoustimes-tooltip">
                <p className="">Equation: {`${d.firstNumber} ${d.operation} ${d.secondNumber} `}</p>
                <p className="">Time spent: {`${d.second} `}</p>
                <p className="">Correct answer: {`${d.isCorrect} `}</p>
                {/* <p className="">Date: {` ${new Date(d.createdAt).toString().split(' GMT')[0] } `}</p>
                <p className="">Time Spent: {` ${payload[0].value} seconds `}</p>
                <p className="">Correct: {` ${d.summary.percentScore}% `}</p> */}
            </div>
            );
        }
        return null;
    };

  return (

    <div className="previousTimes siteDefaultFont">
        { !answers || answers === undefined || answers.length === 0 ? null : 


            <ResponsiveContainer  height={500} className="previousTimesResponsive">

                <BarChart
                    // width={700}
                    // height="100%"
                    data={answers}
                    layout="vertical"
                    margin={{
                        top: 0,
                        right: 10,
                        left: 10,
                        bottom: 5,
                    }}
                    // barSize={100}
                >
                    {/* <p> {JSON.stringify(answers)}</p> */}

                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis label=""  type="number" allowDataOverflow={false} domain={[0, 'dataMax']} scale="auto">
                        <Label value='' position='insideBottom' style={{textAnchor: 'left'}} />
                    </XAxis>

                    <YAxis label="" tickCount="10" angle={0} type="category">
                        <Label angle={-90} value='Question number' position='insideBottomLeft' style={{textAnchor: 'left'}}  />
                    </YAxis>

                    <Tooltip content={<CustomTooltip />}/>
                    {/* <Legend /> */}

                    <Bar dataKey="second" fill="#3629B7">
                        {/* <LabelList dataKey="miliSecond" position="top" /> */}
                        {answers.map((entry, index) => (
                            <Cell key={`cell-${index}`} 
                                strokeWidth="0"
                                stroke="black"
                                radius={[0, 5, 5, 0]}
                                fill={entry.isCorrect ? '#55A55E': '#F75842' }/>
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        }
    </div>
  );
};

export default AnswersChart;
