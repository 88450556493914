import React, { useEffect, useState } from "react";
import Confetti from "../confetti/Confetti";
import CrossIcon from "../../assets/images/Cross.svg";

const Modal = ({ modalStatusHandler, children, answers, drillStatus, summary }) => {
  const [confettiWeight, setConfettiWeight] = useState();


  useEffect(() => {
    if (drillStatus === "done" || drillStatus === 'init') {
      function handleKeyDown(event) {
        if (event.key === "Escape") {
          modalStatusHandler();
        }
      }
      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }
  }, [modalStatusHandler]);

  return (
    <div className="main-modal">
      <div className="container modal">
        <div className="modal-cross">
          <img
            src={CrossIcon}
            alt=""
            className="modal-cross-icon"
            onClick={modalStatusHandler}
          />
        </div>
        <div>{children}</div>
      </div>
      <div className="modal-overlay"></div>
    </div>
  );
};

export default Modal;
