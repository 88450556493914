import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Context } from "../../app/Context";
import StatsDrill from "../stats/StatsDrill"

const Stats = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [drillID, setDrillID] = useState(searchParams.get("drillID"));

  const context = useContext(Context);


  return (
    <div className="container ">
      <div className="statistics">
        <p className="statistics-title">Statistics</p>
        <div className="statistics-description">
          <p>
            These are some statistics calculated based on Math+ users answers.
          </p>
        </div>

        <div>

          <StatsDrill drillID={drillID}/>

        </div>

      </div>
    </div>
  );
};

export default Stats;
