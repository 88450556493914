import React, { useState, useEffect, useContext, useRef, useInsertionEffect } from "react";
import { motion } from "framer-motion";
import { Context } from "../../app/Context";

const Exam = ({ runningDrillHandler, runningDrill, answers }) => {
  const context = useContext(Context);

  const [inputValue, setValue] = useState("");
  const [countSec, setCountSec] = useState(0);
  const [countSubSec, setCountSubSec] = useState(0);
  const [miliSecond, setMiliSecond] = useState(0);

  const mathIndex = runningDrill.questions[runningDrill.currentQuestionPosition];

  const verticalDesktop = useRef(null);
  const verticalMobile = useRef(null);
  const horizontalDesktop = useRef(null);
  const horizontalMobile = useRef(null);

  const focusHandler = () => {
    if (verticalDesktop.current) {
      verticalDesktop.current.focus();
    }
    if (verticalMobile.current) {
      verticalMobile.current.focus();
    }
    if (horizontalDesktop.current) {
      horizontalDesktop.current.focus();
    }
    if (horizontalMobile.current) {
      horizontalMobile.current.focus();
    }
  };

  useInsertionEffect(() => {
    console.log(mathIndex)
  }, [mathIndex])

  useEffect(() => {
    if (verticalDesktop.current) {
      verticalDesktop.current.focus();
    }
    if (verticalMobile.current) {
      verticalMobile.current.focus();
    }
    if (horizontalDesktop.current) {
      horizontalDesktop.current.focus();
    }
    if (horizontalMobile.current) {
      horizontalMobile.current.focus();
    }

  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const date = new Date();
      const miliSecond = date.getMilliseconds();
      setMiliSecond(miliSecond);
      setCountSubSec((prevState) => prevState + 1);
    });
    return () => {
      clearTimeout(timer);
    };
  }, [countSubSec]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountSec((prevState) => prevState + 1);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [countSec]);

  const countSecHandler = () => {
    setCountSec(0);
  };

  const onChangeHanlder = (value) => {
    setValue(value);
  };

  const nextHandler = () => {
    answerHandler();
    focusHandler();
  };

  const onKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      answerHandler();
    }
  };

  const answerHandler = () => {
    var temp = Object.assign({}, mathIndex);
    const mathLength = runningDrill.questions.length;
    if (inputValue && mathLength > answers.length) {
      temp.second = Number(`${countSec}.${miliSecond.toString().slice(0, 2)}`);
      temp.miliSecond =
        countSec * 1000 + Number(`${miliSecond.toString().slice(0, 2)}0`);
      temp.userAnswer = inputValue;
      temp.isCorrect = (temp.correctAnswer === inputValue);
      runningDrillHandler(temp);
      countSecHandler();
      setValue("");
    }
  };

  return (
    <form className="actual-math">

      {/* Vertical Display */}
      {runningDrill.configuration === "vertical" && (
        <div className="math-vertical">
          <table className="center">
            <tbody>
              <tr >
                {
                  [...Array( Math.max(mathIndex.firstNumber.toString().length, mathIndex.secondNumber.toString().length) + 1 ).keys()].map((n) => (
                    <td key={n}>{ n === 0 ? ''
                        : n < (mathIndex.secondNumber.toString().length - mathIndex.firstNumber.toString().length) ? " "
                        : mathIndex.firstNumber.toString().split("")[n - 1 - Math.max(0, mathIndex.secondNumber.toString().length - mathIndex.firstNumber.toString().length)]
                    }</td>
                  ))
                } 
              </tr>

              <tr >
                {
                  [...Array( Math.max(mathIndex.firstNumber.toString().length, mathIndex.secondNumber.toString().length) + 1 ).keys()].map((n) => (
                    <td key={n}>{ n === 0 ? mathIndex.operationDisplayed
                          : n < (mathIndex.firstNumber.toString().length - mathIndex.secondNumber.toString().length) ? " "
                          : mathIndex.secondNumber.toString().split("")[n - 1 - Math.max(0, mathIndex.firstNumber.toString().length - mathIndex.secondNumber.toString().length)]
                    }</td>
                  ))
                } 
              </tr>
            </tbody>
          </table>

          <p className="actual-math-border"></p>
          <input
            type="text"
            className="actual-math-answer actual-math-answer__desktop"
            onChange={(event) => {
              onChangeHanlder(event.target.value);
            }}
            onKeyDown={onKeyDownHandler}
            value={inputValue}
            ref={verticalDesktop}
          />
          <input
            type="number"
            className="actual-math-answer actual-math-answer__mobile"
            onChange={(event) => {
              onChangeHanlder(event.target.value);
            }}
            onKeyDown={onKeyDownHandler}
            value={inputValue}
            ref={verticalMobile}
            autoFocus
          />
        </div>
      )}

      {/* Horizontal Display */}
      {runningDrill.configuration === "horizontal" && (
          <div className="math-horizontal">

            {context.modal.drill.ranges?.first?.toGuess === undefined || context.modal.drill.ranges?.first?.toGuess !== true ?
              (
                <p>{mathIndex.firstNumber}</p>
              ): (
                <React.Fragment>
                  <input
                  type="text"
                  className="actual-math-answer actual-math-answer__horizontal actual-math-answer-hori-desktop"
                  onChange={(event) => {
                    onChangeHanlder(event.target.value);
                  }}
                  size="10"
                  onKeyDown={onKeyDownHandler}
                  value={inputValue}
                  ref={horizontalDesktop}
                />
    
                <input
                  type="number"
                  className="actual-math-answer actual-math-answer__horizontal actual-math-answer-hori-mobile"
                  onChange={(event) => {
                    onChangeHanlder(event.target.value);
                  }}
                  onKeyDown={onKeyDownHandler}
                  value={inputValue}
                  ref={horizontalMobile}
                  autoFocus
                />
              </React.Fragment>
              )
            }

            <p>
              &nbsp;{mathIndex.operationDisplayed}&nbsp;
            </p>


            {context.modal.drill.ranges?.second?.toGuess === undefined || context.modal.drill.ranges?.second?.toGuess !== true ?
              (
                <p>{mathIndex.secondNumber}</p>
              ): (
                <React.Fragment>
                  <input
                  type="text"
                  className="actual-math-answer actual-math-answer__horizontal actual-math-answer-hori-desktop"
                  onChange={(event) => {
                    onChangeHanlder(event.target.value);
                  }}
                  size="10"
                  onKeyDown={onKeyDownHandler}
                  value={inputValue}
                  ref={horizontalDesktop}
                />
    
                <input
                  type="number"
                  className="actual-math-answer actual-math-answer__horizontal actual-math-answer-hori-mobile"
                  onChange={(event) => {
                    onChangeHanlder(event.target.value);
                  }}
                  onKeyDown={onKeyDownHandler}
                  value={inputValue}
                  ref={horizontalMobile}
                  autoFocus
                />
              </React.Fragment>
              )
            }

            <p>=</p>

            {context.modal.drill.ranges?.total?.toGuess !== undefined && context.modal.drill.ranges?.total?.toGuess === false ?
              (
                <p>{mathIndex.resultNumber}</p>
              ): (
                <React.Fragment>
                <input
                type="text"
                className="actual-math-answer actual-math-answer__horizontal actual-math-answer-hori-desktop"
                onChange={(event) => {
                  onChangeHanlder(event.target.value);
                }}
                size="10"
                onKeyDown={onKeyDownHandler}
                value={inputValue}
                ref={horizontalDesktop}
              />
  
              <input
                type="number"
                className="actual-math-answer actual-math-answer__horizontal actual-math-answer-hori-mobile"
                onChange={(event) => {
                  onChangeHanlder(event.target.value);
                }}
                onKeyDown={onKeyDownHandler}
                value={inputValue}
                ref={horizontalMobile}
                autoFocus
              />
              </React.Fragment>
              )
            }
          </div>
      )}

      {/* Word problem */}
      {runningDrill.configuration === "text" && (
          <div>

            <div className='math-prompt-text'>
              <p>{mathIndex.prompt}</p>
            </div>

            <input
              type="text"
              className="actual-math-answer actual-math-answer__desktop"
              onChange={(event) => {
                onChangeHanlder(event.target.value);
              }}
              onKeyDown={onKeyDownHandler}
              value={inputValue}
              ref={verticalDesktop}
            />
            <input
              type="number"
              className="actual-math-answer actual-math-answer__mobile"
              onChange={(event) => {
                onChangeHanlder(event.target.value);
              }}
              onKeyDown={onKeyDownHandler}
              value={inputValue}
              ref={verticalMobile}
              autoFocus
            />

          </div>
      )}

      <motion.div
        className="actual-math-next-btn"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={nextHandler}
      >
        Next
      </motion.div>
    </form>
  );
};

export default Exam;
