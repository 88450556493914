import React, { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import ArrowUp from "../../assets/images/ArrowUp.svg";
import ArrowDown from "../../assets/images/ArrowDown.svg";
import { Context } from "../../app/Context";

const Math = () => {
  const [addition, setAddition] = useState();
  const [subtraction, setSubtraction] = useState();
  const [multiplications, setMultiplications] = useState();
  const [sectionsDisplayed, setsectionsDisplayed] = useState({});

  const context = useContext(Context);

  useEffect(() => {
    const sects = JSON.parse(localStorage.getItem("sectionsDisplayed"));
    if (!sects)
      setsectionsDisplayed({})
    else
      setsectionsDisplayed(sects)
  }, []);

  useEffect(() => {
    localStorage.setItem("sectionsDisplayed", JSON.stringify(sectionsDisplayed));
  }, [sectionsDisplayed]);


  const drillClicked = (event, el) => {   
    if (event.shiftKey) {
      window.open(`/stats?drillID=${el._id}`,"_self");
    } else {
      context.modalStatusHandler(el)
    }
 }

  return (
    <div>

      <div className="container">
        <p className="filter-title">Select an exercise:</p>
      </div>

      {Object.keys(context.configurationFile.sections).map((k,v) => (

      <React.Fragment>
        {Object.values(context.configurationFile.sections[k].drills).reduce((accumulator, currentValue) => accumulator + (currentValue.filterItems.includes(context.activeGradeFilterID) ? 1 : 0), 0) === 0 ? null : (

        <div className="math container" key={k}>
          <div className="math-content">
            <div className="math-top">
              <div className="math-flex">
                <p className="math-title">{context.configurationFile.sections[k].title}</p>
                <div
                  className="math-content-expend"
                  onClick={() => setsectionsDisplayed({...sectionsDisplayed, [k]: !(sectionsDisplayed[k])} )}
                >
                  {sectionsDisplayed[k] === undefined || sectionsDisplayed[k] !== false ? (
                    <>
                      <p className="math-content-expend-text">See less</p>
                      <img src={ArrowUp} alt="ArrowUp" />
                    </>
                  ) : (
                    <>
                      <p className="math-content-expend-text">See All</p>
                      <img src={ArrowDown} alt="ArrowDown" />
                    </>
                  )}
                </div>
              </div>
              <p className="math-description">
                {context.configurationFile.sections[k].description}
              </p>
            </div>

            {(sectionsDisplayed[k] === undefined || sectionsDisplayed[k] !== false) && (
              <motion.div
                className="math-main-option"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, height: "100%" }}
              >
                {context.configurationFile.sections[k].drills.map((el) =>
                  el.filterItems.map(
                    (item) =>
                      item === context.activeGradeFilterID && (
                        <div
                          className="math-option"
                          key={el._id}
                          onClick={e => drillClicked(e, el)}
                        >

                          {!el.new ? null : 
                            <div className="ribbon-wrapper">
                              <div className="ribbon">NEW</div>
                            </div>
                          }
                          <p className="math-option-number hidden">{el.number}</p>
                          <p className="math-option-title">{el.title}</p>
                          <p className="math-option-description">
                            {el.description}
                          </p>
                        </div>
                      )
                  )
                )}
              </motion.div>
            )}
          </div>
        </div>
      
        )}
        </React.Fragment>
      ))}

    </div>
  );
};

export default Math;
