import React, { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import StudentImg from "../../assets/images/student.svg";
import StudentTimerImg from "../../assets/images/StudentTimer.svg";
import PreviousTimes from "./PreviousTimes"
import Axios from "../../utils/Axios";
import { Context } from "../../app/Context";

const Intro = ({ drill, getStartedHandler }) => {
  const context = useContext(Context);
  const [previousTimes, setPreviousTimes] = useState([]);
  const [previousStats, setPreviousStats] = useState({});

  useEffect(() => {

    function handleKeyDown(event) {
      if (event.key === "Enter") {
        getStartedHandler();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [getStartedHandler]);

  useEffect(() => {
    console.log(`Initializing the previous times`);
    console.log(previousTimes);
    console.log(context.modal.drill._id)

    // Retrieve the previous records
    const params = {
      params: {
        sessionID: context.sessionID,
        drillID: context.modal.drill._id,
        summary: true,
        userID: context.userInfo.userID
      }
    };

    Axios.get(`/api/exams`, params)
    .then((response) => {
      console.log(response.data.response.Items);
      let ordered = response.data.response.Items.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
      let prev = {minTime: -1, avgTime: 0}
      let den = 0;
      ordered.forEach(e => {
        if (e.summary != null && e.summary.percentScore === 100) {
          if (prev.minTime == -1 || e.summary.timeSpent < prev.minTime)
            prev.minTime = e.summary.timeSpent;
          prev.avgTime = prev.avgTime + e.summary.timeSpent;
          den += 1;
        }
      });
      prev.avgTime = Math.round(prev.avgTime / den * 100) / 100
      prev.lastDate = new Date(ordered[ordered.length - 1].createdAt).toDateString();

      setPreviousStats(prev)
      setPreviousTimes(ordered)
    })
    .catch((e) => {
      console.log("Something went wrong while retrieving the previous records");
      console.log(e);
    });
  }, [])

  return (

    <div className="math-intro">


      <div className="math-intro-left">
        <div>
          <p className="math-intro-title">{drill.introduction.title}</p>
          <p className="math-intro-des">{drill.introduction.description}</p> 
        </div>

        {drill.introduction.example === undefined ? null : (

          <div className="intro-flex-human">

            <div className="intro-example-flex">
              <div>
                <p className={`math-intro-example-titile`}>Example:</p>
                <div className="math-intro-example-example">
                  <p className="math-intro-example-number">
                    {drill.introduction.example?.first} =
                  </p>
                  <p className="math-intro-example-input"></p>
                </div>
              </div>
              <div className="math-intro-example hidden">
                <p className="math-intro-example-titile">But not</p>
                <div className="math-intro-example-example">
                  <p className="math-intro-example-number">
                    <span className="math-intro-example-number-wrong">
                      {drill.introduction.example?.second.split(" ")[0]}{" "}
                    </span>
                    {drill.introduction.example?.second.split(" ")[1]}{" "}
                    {drill.introduction.example?.second.split(" ")[2]}
                  </p>
                  <p className="math-intro-example-input"></p>
                </div>
              </div>
            </div>
            
            {/* <div className="intro-flex-human-right" style={{ backgroundImage: `url(${StudentImg})` }}> */}
              {/* <Human /> */}
            {/* </div> */}
          </div>

        )}

        <div className="math-intro-btn-wrapper">
          <motion.div
            className="math-intro-btn"
            whileHover={{ scale: 1.1 }}
            onClick={getStartedHandler}
          >
            Get Started
          </motion.div>
        </div>


      </div>


      <div className="intro-right-panel">

        {previousTimes.length == 0 ? 
          <div className="intro-right">
            <div className="intro-right-human" style={{ backgroundImage: `url(${StudentImg})` }}></div>
          </div>
         : 
          // <div className="intro-previous-times">
          //   <PreviousTimes previousTimes={previousTimes}/>
          // </div>


          <div className="intro-right">
            <div className="introduction-previous-activities-label">Previous performances</div>
            <div className="introduction-previous-activities">
              <div className="intro-right-human-timer" style={{ backgroundImage: `url(${StudentTimerImg})` }}></div>
              <p className="introduction-previous-activities-best">{previousStats.minTime} Seconds</p>
              <p className="introduction-previous-activities-desc">Best time realized so far</p>
              <p className="introduction-previous-activities-avg">{previousStats.avgTime} Seconds</p>
              <p className="introduction-previous-activities-desc">Average time</p>
              {/* <p className="introduction-previous-activities-lastdate">{JSON.stringify(previousTimes)}</p> */}
              <p className="introduction-previous-activities-lastdate">{previousStats.lastDate}</p>
              <p className="introduction-previous-activities-desc">Date of your last activity</p>
            </div>
          </div>
        }

      </div>

      
    </div>
  );
};

export default Intro;
