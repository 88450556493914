import React, { useContext } from "react";
import { motion } from "framer-motion";
import Modal from "../modal/Modal";
import MathIntro from "../modal/Introduction";
import CountDown from "../countDown/CountDown";
import Exam from "../exam";
import Summary from "../modal/Summary";
import { Context } from "../../app/Context";
import { logDOM } from "@testing-library/react";

const ExamModal = () => {
  const context = useContext(Context);

  return (
    <div>
      {context.modal.toggle && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <Modal
            modalStatusHandler={context.modalStatusHandler}
            drillStatus={context.drillStatus}
            answers={context.answers}
            summary={context.summary}
          >
            {context.drillStatus === "init" && (
              <MathIntro
                getStartedHandler={context.getStartedHandler}
                drill={context.modal.drill}
              />
            )}
            {context.drillStatus === "start" && (
              <motion.div
                initial={{ opacity: 0, x: 500 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <CountDown
                  countFinishHandler={context.countFinishHandler}
                  drill={context.modal.drill}
                />
              </motion.div>
            )}
            {context.drillStatus === "finish" && (
              <motion.div
                initial={{ opacity: 0, x: 500 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <Exam
                  drill={context.modal.drill}
                  runningDrill={context.runningDrill}
                  runningDrillHandler={context.runningDrillHandler}
                  answers={context.answers}
                />
              </motion.div>
            )}
            {context.drillStatus === "done" && (
              <motion.div
                initial={{ opacity: 0, x: 500 }}
                animate={{ opacity: 1, x: 0 }}
              >
                <Summary
                  doitagainHandler={context.doitagainHandler}
                  answers={context.answers}
                  summary={context.summary}
                />
              </motion.div>
            )}
          </Modal>
        </motion.div>
      )}
    </div>
  );
};

export default ExamModal;
