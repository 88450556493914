import React from "react";
import ProgressBar from "./ProgressBar";
import Exam from "./Exam";

const Index = ({ drill, runningDrill, runningDrillHandler, answers }) => {
  return (
    <div className="main-actual">
      <p className="math-intro-title math-intro-title__center">
        {drill.introduction.title}
      </p>
      <ProgressBar runningDrill={runningDrill} answers={answers} />
      <Exam
        runningDrill={runningDrill}
        runningDrillHandler={runningDrillHandler}
        answers={answers}
      />
    </div>
  );
};

export default Index;
