export function getRandomIntFromRangeIncluded(min, max) {
  let spread = max - min;
  return parseInt(Math.floor(Math.random() * (spread + 1))) + min;
} 

export function switchNumbersRandomly(firstDigit, secondDigit) {
  let res = { firstDigit: firstDigit, secondDigit: secondDigit };
  const funcRes = getRandomIntFromRangeIncluded(0, 1)

  if (funcRes === 1) {
    res.firstDigit = secondDigit;
    res.secondDigit = firstDigit;
  }
  return res;
}

export function generateQuestion(vocabularies, exam) {
  let ranges = {
    first: {
      lower: exam.ranges?.first?.lower !== undefined ? exam.ranges?.first?.lower : 0,
      upper: exam.ranges?.first?.upper !== undefined ? exam.ranges?.first?.upper : 9
    },
    second : {
      lower: exam.ranges?.second?.lower !== undefined ? exam.ranges?.second?.lower : 1,
      upper: exam.ranges?.second?.upper !== undefined ? exam.ranges?.second?.upper : 9
    },
    total: {
      lower: exam.ranges?.total?.lower !== undefined ? exam.ranges?.total?.lower : 0,
      upper: exam.ranges?.total?.upper !== undefined ? exam.ranges?.total?.upper : Number.MAX_VALUE
    }
  }

  let firstDigit = Math.round(Math.random() * (ranges.first.upper - ranges.first.lower) + ranges.first.lower);
  let secondDigit = Math.round(Math.random() * (ranges.second.upper - ranges.second.lower) + ranges.second.lower);

  // Testing if there is an equality constraint
  if (exam.ranges?.second?.same_as_first)
    secondDigit = firstDigit;

  // Testig if we need to increase or decrease the second number
  if (exam.ranges?.second?.add_to_first !== undefined) {
    secondDigit = firstDigit + exam.ranges?.second?.add_to_first;

    if (!exam.noRandomOrder) {
      let n = switchNumbersRandomly(firstDigit, secondDigit);
      firstDigit = n.firstDigit;
      secondDigit = n.secondDigit;
    }
  }

  let res = 0;
  if (exam.operator === "+") {
    res = firstDigit + secondDigit;

    if (res < ranges.total.lower || res > ranges.total.upper) {
      let lower = Math.max(ranges.second.lower, (ranges.total.lower - firstDigit))
      let upper = Math.min(ranges.second.upper, (ranges.total.upper - firstDigit))
      secondDigit = Math.round(Math.random() * (upper - lower) + lower)
  
      if (!exam.noRandomOrder) {
        let n = switchNumbersRandomly(firstDigit, secondDigit);
        firstDigit = n.firstDigit;
        secondDigit = n.secondDigit;
      }

      res = firstDigit + secondDigit;
    }    

  } else if (exam.operator === "-") {
    let mi = Math.min(firstDigit, secondDigit);
    let ma = Math.max(firstDigit, secondDigit);
    firstDigit = ma;
    secondDigit = mi;

    res = firstDigit - secondDigit;

  } else if (exam.operator === "x") {

    if (!exam.noRandomOrder) {
      let n = switchNumbersRandomly(firstDigit, secondDigit);
      firstDigit = n.firstDigit;
      secondDigit = n.secondDigit;
    }

    res = firstDigit * secondDigit;

  } else if (exam.operator === "/") {
    if (exam.ranges?.total?.lower !== undefined && exam.ranges?.total?.upper !== undefined) {
      // if (exam.ranges?.total?.lower !== undefined && exam.ranges?.total?.upper !== undefined && (exam.ranges?.second?.lower === exam.ranges?.second?.upper)) {
        res = Math.round(Math.random() * (ranges.total.upper - ranges.total.lower) + ranges.total.lower);
      firstDigit = secondDigit * res
    }
    res = firstDigit / secondDigit;
  } 

  // Find correct answer based on what number should be guessed
  let correctAnswer = String(res);
  if (exam.ranges?.total?.toGuess !== undefined && exam.ranges?.total?.toGuess === false) {
    correctAnswer = String(exam.ranges?.first?.toGuess === true ? firstDigit : secondDigit)
  }

  // Create final object
  const mathObj = {
    _id: null,
    firstNumber: firstDigit,
    secondNumber: secondDigit,
    resultNumber: res,
    correctAnswer,
    operation: exam.operator,
    operationDisplayed: exam.operator === "*" ? "x" : exam.operator === "/" ? "÷" : exam.operator
  };

  // Calculate the word problem prompt if available
  if (exam.prompts) {
    let pbs = exam.prompts;
    let pb = pbs[Math.round(Math.random() * (pbs.length - 1))];
    let prompt = pb.prompt
    mathObj.promptTemplate = prompt

    // Replace by math problem equation
    if (prompt.indexOf('${first}') > -1)
      prompt = prompt.replaceAll('${first}', firstDigit)
    if (prompt.indexOf('${second}') > -1)
      prompt = prompt.replaceAll('${second}', secondDigit)

    // Copy the vocabularies in a temp variable
    let vocs = JSON.parse(JSON.stringify(vocabularies))
    let variables = {}

    // Search for general vocabularies
    let r = /\$\{(.+?)(#([1-9]+))?\}/g
    let it = prompt.matchAll(r)
    for (const match of it) {
      let variable = match[0]
      let vocName = match[1]
      console.log(vocs[vocName])

      if (!(variable in variables) && vocName in vocs) {
        let val = getRandomItem(vocs[vocName].list)
        vocs[vocName].list = removeItem(vocs[vocName].list, val)
        variables[variable] = val
      }
    }

    Object.entries(variables).forEach(([key, value]) => {prompt = prompt.replaceAll(key, value)})

    mathObj.prompt = prompt
  }


  return mathObj;
}

function getRandomItem(arr) {
  let randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

function removeItem(names, name) {
  return names.filter(n => n !== name);
}
