import React, { useState, useEffect, createContext } from "react";
import ReactGA from "react-ga4";
import configurationFile from "../db/content.json";
import Axios from "../utils/Axios";
import { generateQuestion } from "../utils/QuestionGeneratorFunctions";

export const Context = createContext();

const ContextComponent = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});

  const [filterData, setFilterData] = useState([]);
  const [activeGradeFilterID, setActiveGradeFilterID] = useState(
    localStorage.getItem("activeGradeFilterID")
  );
  const [modal, setModal] = useState({
    toggle: false,
    drill: null
    // operation: "",
  });

  const [drillStatus, setDrillStatus] = useState("init");
  const [runningDrill, setRunningDrill] = useState({
    currentQuestionPosition: 0,
    questions: [],
    configuration: null,
  });
  const [examID, setExamID] = useState("");
  const [answers, setAnswers] = useState([]);

  // const activeGradeFilterID = localStorage.getItem("activeGradeFilterID");
  // const sessionLocal = localStorage.getItem("sessionID");
  const [sessionID, setSessionID] = useState(localStorage.getItem("sessionID"));

  const [summary, setSummary] = useState({
    exactScore: 0,
    percentScore: 0,
    timeSpent: 0,
    correctAnswer: 0,
    wrongAnswer: 0,
  });

  const gradeFilterChangeHandler = (id) => {
    const temp = [...filterData];
    const findIndex = temp.findIndex((el) => el._id === id);
    const findTrue = temp.findIndex((el) => el.isActive === true);
    const findData = temp[findIndex];
    const findData2 = temp[findTrue];

    if (findData.isActive !== "disable") {
      temp[findData] = findData.isActive = true;
      if (findData2) {
        temp[findData2] = findData2.isActive = false;
      }
      setFilterData(temp);
      setActiveGradeFilterID(id);
      localStorage.setItem("activeGradeFilterID", id);
    }
    googleAnalyticsEvent("click_age_group", id);
  };

  const googleAnalyticsEvent = (actionName, label) => {
    const params = {
      label
    }
    ReactGA.event(actionName, params); 
    // ReactGA.event({
    //   action: actionName,
    //   category: sessionID || "Null",
    // });
  };

  const modalStatusHandler = (obj) => {
    setModal({
      toggle: !modal.toggle,
      drill: obj
      // operation: operation,
    });
    setDrillStatus("init");
    setAnswers([]);
    googleAnalyticsEvent("click_drill", obj._id);
  };

  const getStartedHandler = () => {
    setDrillStatus("start");
    examQuestionsGenerator();
    examIDgenetator();
    console.log(modal.drill._id)
    googleAnalyticsEvent("exam_start", modal.drill._id);
  };

  const countFinishHandler = () => {
    setDrillStatus("finish");
  };

  const runningDrillHandler = (ansObj) => {
    const temp = [...answers];

    if (
      runningDrill.currentQuestionPosition + 1 <
      runningDrill.questions.length
    ) {
      setRunningDrill({
        ...runningDrill,
        currentQuestionPosition: runningDrill.currentQuestionPosition + 1,
      });
    } else {
      // Marking the end of the drill to display the summary page
      setDrillStatus("done");
      googleAnalyticsEvent("exam_done", modal.drill._id);
    }

    ansObj.sessionID = sessionID;
    temp.push(ansObj);
    setAnswers(temp);

    // Saving the question and the answer
    Axios.put(`/api/exams/${examID}/question`, ansObj)
      .then(() => {})
      .catch(() => {
        console.log("Something went wrong!");
      });
  };

  const doitagainHandler = () => {
    setDrillStatus("start");
    setAnswers([]);
    examQuestionsGenerator();
    examIDgenetator();
    googleAnalyticsEvent("click_do_it_again", modal.drill._id);
  };

  const examIDgenetator = () => {
    // TODO: this is dirty, the default value should be already in the local
    if (
      localStorage.getItem("activeGradeFilterID") === undefined ||
      localStorage.getItem("activeGradeFilterID") == null
    ) {
      let ageFilter = null;
      filterData.map(
        (el) => (ageFilter = el.isActive === true ? el._id : ageFilter)
      );

      localStorage.setItem("activeGradeFilterID", ageFilter);
    }

    // Create new Exam and Get a UID
    Axios.post("/api/exams", {
      drillID: modal.drill._id,
      sessionID: sessionID,
      ageFilter: localStorage.getItem("activeGradeFilterID"),
      userID: userInfo.userID,
    })
      .then((response) => {
        console.log(response.data);
        setExamID(response.data.examID);
      })
      .catch(() => {
        console.log("Something went wrong!");
      });
  };

  const examQuestionsGenerator = () => {
    const tmpDrill = {
      configuration: modal.drill.display || "horizontal",
      questions: [],
    };

    const noRepeat = modal.drill.noRepeat != null ? modal.drill.noRepeat : true;

    for (let i = 1; i <= (modal.drill.totalQuestions || configurationFile.defaultParams.totalQuestions); i++) {
      let valid = false;
      let question = null;
      for (let j = 0; j < 1000 && !valid; j++) {
        valid = true;
        question = generateQuestion(configurationFile.vocabularies, modal.drill);
        question._id = i;

        // Making sure what we are not asking the same question twice in a row
        if (noRepeat && tmpDrill.questions.length) {
          let prev = tmpDrill.questions[tmpDrill.questions.length - 1];
          if (
            !(
              prev.firstNumber === question.firstNumber &&
              prev.secondNumber === question.secondNumber &&
              prev.operation === question.operation
            )
          )
            valid = true;
          else {
            valid = false;
          }
        }
      }
      tmpDrill.questions.push(question);
    }

    setRunningDrill({
      ...tmpDrill,
      currentQuestionPosition: 0,
    });
  };

  useEffect(() => {
    const temp = configurationFile.filter.items;
    const findTrue = temp.find((el) => el.isActive === true);
    const localIdNumber = Number(activeGradeFilterID);

    if (activeGradeFilterID) {
      const findTrue = temp.findIndex((el) => el.isActive === true);
      const findData2 = temp[findTrue];

      if (findData2) {
        temp[findData2] = findData2.isActive = false;
      }

      const findIndex = temp.findIndex((el) => el._id === localIdNumber);
      const findData = temp[findIndex];
      temp[findData] = findData.isActive = true;
      setActiveGradeFilterID(localIdNumber);
    } else {
      setActiveGradeFilterID(findTrue._id);
    }
    setFilterData(temp);
  }, [activeGradeFilterID]);

  useEffect(() => {
    if (answers.length === runningDrill.currentQuestionPosition + 1) {
      let score = 0;

      answers.forEach((el) => {
        if (el.isCorrect) {
          score++;
        }
      });

      let miliSecond = 0;
      answers.forEach((el) => {
        miliSecond = miliSecond + el.miliSecond;
      });

      let summ = {
        exactScore: score,
        percentScore: (100 / answers.length) * score,
        timeSpent: miliSecond / 1000,
        correctAnswer: score,
        wrongAnswer: answers.length - score,
      };

      // Saving the summary
      Axios.put(`/api/exams/${examID}`, {
        summary: summ,
      })
        .then((response) => {
          setSummary({
            ...summ,
            isPushedToDB: true,
          });
          console.log(response.data.message);
        })
        .catch(() => {
          console.log("Something went wrong while storing the summary");
        });

      setSummary(summ);
    }
  }, [answers, runningDrill.currentQuestionPosition]);

  return (
    <Context.Provider
      value={{
        configurationFile: configurationFile,
        filterData: filterData,
        activeGradeFilterID: activeGradeFilterID,
        modal: modal,
        drillStatus: drillStatus,
        runningDrill: runningDrill,
        sessionID: sessionID,
        answers: answers,
        summary: summary,
        userInfo,
        setSessionID,
        setUserInfo,
        setModal: setModal,
        googleAnalyticsEvent,
        gradeFilterChangeHandler: gradeFilterChangeHandler,
        modalStatusHandler: modalStatusHandler,
        getStartedHandler: getStartedHandler,
        countFinishHandler: countFinishHandler,
        runningDrillHandler: runningDrillHandler,
        doitagainHandler: doitagainHandler,
        googleAnalyticsEvent: googleAnalyticsEvent,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextComponent;
