import React, { useState } from "react";
import ContactImg from "../../assets/images/Contact.svg";

const ContactUsForm = ({ submitHandler }) => {
  const [contact, setContact] = useState({
    email: "",
    message: "",
  });

  const onChangeHandler = (event) => {
    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    submitHandler(contact);
  };

  return (
    <div className="container">
      <div
        className="contact"
        style={{ backgroundImage: `url(${ContactImg})` }}
      >
        <p className="contact-overlay"></p>
        <div className="contact-container">
          <div>
            <p className="contact-title">We’d love to hear from you</p>
            <p className="contact-description">
              Any feedback, questions or suggestions? Please send me a message!
            </p>
            <form className="contact-form">
              <input
                type="email"
                placeholder="Email address"
                className="contact-email"
                value={contact.email}
                onChange={onChangeHandler}
                required
                name="email"
              />
              <textarea
                placeholder="Your message"
                className="contact-message"
                value={contact.message}
                onChange={onChangeHandler}
                required
                name="message"
              />
              <button className="contact-submit-btn" onClick={onClickHandler}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
