import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

const ConfettiCompo = ({ answers, drillStatus, summary, previousBestTime }) => {
  let score = 0;
  let mathLength = answers.length;

  answers.forEach((el) => {
    if (el.isCorrect) {
      score++;
    }
  });

  useEffect(() => {
    console.log(summary);

  }, [summary]);

  const scorePercent = (100 / mathLength) * score;

  return (
    <div>
      {drillStatus === "done" && (
        <div>
          {scorePercent === 100 && (previousBestTime === null || summary.timeSpent <= previousBestTime) && (
            <Confetti
              numberOfPieces="500"
              style={{ width: "100%", height: "350px" }}
            />
          )}
          
          {scorePercent === 100 && (
            <Confetti
              numberOfPieces="50"
              style={{ width: "100%", height: "350px" }}
            />
          )}
          {/* {scorePercent <= 99 && scorePercent >= 50 && (
            <Confetti
              numberOfPieces="100"
              style={{ width: "100%", height: "350px" }}
            />
          )} */}
        </div>
      )}
    </div>
  );
};

export default ConfettiCompo;
