import React, { useState } from "react";
import Axios from "../../utils/Axios";
import ContactUsForm from "./ContactUsForm";
import ContactUsSuccessMsg from "./ContactUsSuccessMsg";

const Index = () => {
  const [contact, setContact] = useState("contact");

  const submitHandler = (contact) => {
    const sessionLocal = localStorage.getItem("sessionID");
    Axios.post("/api/feedbacks", {
      sessionID: sessionLocal,
      email: contact.email,
      message: contact.message,
    })
      .then(() => {
        setContact("success");
      })
      .catch(() => {
        console.log("Something went wrong!");
      });
  };

  return (
    <div>
      {contact === "contact" ? (
        <ContactUsForm submitHandler={submitHandler} />
      ) : (
        <ContactUsSuccessMsg />
      )}
    </div>
  );
};

export default Index;
